<template>
  <!-- 店铺管理 -->
  <div class="page-card-demo">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      reset
      :form-item-arr="searchFormItemArr"
    />
    <!-- 表格  分页 -->
    <div v-isPermission="'store_view'" class="page-container-table">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      >
        <template #city="{row}">
          {{ row.placeProvinceName?row.placeProvinceName + '/':'' }}{{ row.placeCityName }}
        </template>
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
    <!-- operationState为1的时候是审核结果  为2的时候是关闭原因  为3的时候审核   为4的时候是填写关闭意见 -->
    <el-dialog :title="dialogTitle" width="32%" :visible.sync="dialogAuditFormVisible" :close-on-click-modal="false">
      <div v-if="operationState===1" class="audit-result-box">
        <p><span>审核结果</span>{{ currentRow.reviewResult==='00'?'驳回':'通过' }}</p>
        <p><span>审核人</span>{{ currentRow.reviewRealname }} <b v-if="currentRow.reviewUser">({{ currentRow.reviewUser }})</b></p>
        <p><span>审核时间</span>{{ currentRow.reviewTime }}</p>
        <p><span>审核意见</span>{{ currentRow.reviewComment }}</p>
      </div>
      <div v-else-if="operationState===2" class="audit-result-box">
        <p><span>操作人</span>{{ currentRow.closeRealname }} <b v-if="currentRow.closeUser">({{ currentRow.closeUser }})</b></p>
        <p><span>操作时间</span>{{ currentRow.closeTime }}</p>
        <p><span>关闭意见</span>{{ currentRow.closeComment }}</p>
        <p>
          <span>附件</span>{{ currentRow.closeAttachmentName }}
          <el-button v-if="currentRow.closeAttachment" type="text" @click="viewImage">
            查看
          </el-button>
          <el-button v-if="currentRow.closeAttachment" type="text" @click="downloadImageFile">
            下载
          </el-button>
        </p>
      </div>
      <el-form v-else-if="operationState===3||operationState===4" ref="auditFrom" :model="auditForm" label-width="80px">
        <el-form-item
          v-if="operationState===3"
          label="审核结果"
          prop="reviewResult"
          :rules="[
            { required: true, message: '请选择', trigger: 'change' }
          ]"
        >
          <el-radio-group v-model="auditForm.reviewResult">
            <el-radio label="01">
              通过
            </el-radio>
            <el-radio label="00">
              驳回
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="operationState===3"
          label="审核意见"
          prop="reviewComment"
          :rules="auditForm.reviewResult==='00'?[
            { required: true, message: '请输入', trigger: 'change' }
          ]:[]"
        >
          <el-input v-model="auditForm.reviewComment" placeholder="请输入" type="textarea" maxlength="30" show-word-limit />
        </el-form-item>
        <el-form-item
          v-if="operationState===4"
          label="关闭意见"
          prop="closeComment"
          :rules="[{ required: true, message: '请输入', trigger: 'change' }]"
        >
          <el-input v-model="auditForm.closeComment" placeholder="请输入关闭意见" type="textarea" maxlength="30" show-word-limit />
        </el-form-item>
        <el-form-item
          v-if="operationState===4"
          label="附件"
        >
          <FileUpload
            ref="fileUpload"
            :file-size="5"
            :file-format="'pdf, jpg, png, jpeg'"
            :edit-file-name="currentRow.closeAttachmentName"
            :loading="true"
            @getFile="getFile"
          />
        </el-form-item>
      </el-form>
      <div v-if="operationState===3||operationState===4" slot="footer" class="dialog-footer">
        <el-button size="small" plain class="widen-button" @click="dialogAuditFormVisible = false">
          取 消
        </el-button>
        <el-button v-if="operationState===3" :loading="$store.state.loading" size="small" class="widen-button" type="primary" @click="determineAudit('audit')">
          确 定
        </el-button>
        <el-button v-else-if="operationState===4" :loading="$store.state.loading" size="small" class="widen-button" type="primary" @click="determineAudit">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import Table from '@/components/Table.vue'
import FileUpload from '@/components/FileUpload.vue'
import Pagination from '@/components/Pagination2.vue'
import { shopListPage, reviewShop, closeShop } from '@/api/shoppingMall'
import { getSelectAreaTree } from '@/api/login.js'
import { downloadFile2 } from '@/utils/util.js'
export default {
  components: { FormSearch, Table, Pagination, FileUpload },
  data() {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      dialogTitle: '审核店铺',
      dialogAuditFormVisible: false,
      operationState: 0,
      auditForm: {
        reviewComment: '',
        reviewResult: '',
        closeComment: ''
      },
      listData: [],
      total: 0,
      loading: false,
      searchFormItemArr: [
        { type: 'input', label: '公司名称', value: 'cmpName', width: '80px' },
        { type: 'cascader', label: '所在地区', value: 'city', props: { value: 'code', label: 'name', checkStrictly: true }, options: [], width: '80px' },
        { type: 'select', label: '状态', value: 'state', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('SHOP_STATE'), width: '80px' }
      ],
      itemData: [
        { label: '公司名称', prop: 'cmpName' },
        { label: '所在地区', prop: 'city' },
        { label: '店铺联系人', prop: 'contactPerson' },
        { label: '店铺联系电话', prop: 'contactPhone' },
        { label: '申请时间', prop: 'applyTime' },
        { label: '状态', prop: 'state', child: this.$store.getters.getDictionaryItem('SHOP_STATE') }
      ],
      // operationState为1的时候是审核结果  为2的时候是关闭原因  为3的时候审核   为4的时候是填写关闭意见
      operationButton: [
        { permission: 'store_audit', val: 'state', num: '01', bType: 'primary', label: '审核', handleEvent: this.operationSeries, withParameters: 3 },
        { permission: 'store_audit_result', childOne: [{ val: 'state', num: '02' }, { val: 'state', num: '03' }, { val: 'state', num: '05' }], bType: 'primary', label: '审核结果', handleEvent: this.operationSeries, withParameters: 1 },
        { permission: 'store_close_cause', val: 'state', num: '04', bType: 'primary', label: '关闭原因', handleEvent: this.operationSeries, withParameters: 2 },
        { permission: 'store_close', val: 'state', num: '05', bType: 'primary', label: '关闭店铺', handleEvent: this.operationSeries, withParameters: 4 },
        { permission: 'store_details', bType: 'primary', label: '查看详情', handleEvent: this.viewDetails }
      ],
      dialogVisible: false,
      currentRow: {}
    }
  },
  mounted() {
    // 获取地区
    const filterLevel = (list, level = 1) => list.map(v => ({ ...v, children: level <= 1 ? null : filterLevel(v.children, level - 1) }))
    getSelectAreaTree('', (res) => {
      this.searchFormItemArr[1].options = filterLevel(res.data, 2)
    })
  },
  methods: {
    // 店铺审核  关闭店铺的确定按钮
    determineAudit(type) {
      this.$refs['auditFrom'].validate((valid) => {
        if (valid) {
          const api = type === 'audit' ? reviewShop : closeShop
          api({ ...this.auditForm, id: this.currentRow.id }, () => {
            this.$message.success('审核成功')
            this.getdata(true)
            this.dialogAuditFormVisible = false
          })
        }
      })
    },
    // 下载图片文件
    downloadImageFile() {
      const arr = this.currentRow.closeAttachment.split('.')
      downloadFile2(this.$fileUrl + this.currentRow.closeAttachment, this.currentRow.closeAttachmentName, arr[arr.length - 1])
    },
    // 查看图片
    viewImage() {
      window.open(this.$fileUrl + this.currentRow.closeAttachment)
    },
    getFile(obj) {
      this.auditForm.closeAttachment = obj.fileUrl
      this.auditForm.closeAttachmentName = obj.fileName
    },
    // // 获取分页数据
    getdata(type) {
      switch (this.formInline.city && this.formInline.city.length) {
        case 1:
          this.formInline.placeProvince = this.formInline.city[0]
          this.formInline.placeCity = ''
          break
        case 2:
          this.formInline.placeProvince = this.formInline.city[0]
          this.formInline.placeCity = this.formInline.city[1]
          break
        default:
          break
      }
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      shopListPage(this.formInline, res => {
        this.listData = [...res.data.records]
        this.total = res.data.total
      })
    },
    // 操作相关
    operationSeries(row, index) {
      this.dialogAuditFormVisible = true
      this.operationState = index
      this.currentRow = { ...row }
      switch (index) {
        case 1:
          this.dialogTitle = '审核结果'
          break
        case 2:
          this.dialogTitle = '关闭原因'
          break
        case 3:
          this.dialogTitle = '审核店铺'
          break
        case 4:
          this.dialogTitle = '关闭店铺'
          break
      }
      this.$nextTick(() => {
        this.$refs['auditFrom'] && this.$refs['auditFrom'].resetFields()
      })
    },
    // 查看详情
    viewDetails({ id }) {
      this.$router.push({
        path: '/shoppingMall/shopDetail',
        query: { id }
      })
    }
  }
}
</script>

  <style scoped lang="scss">
  .audit-result-box{
    p{
      display: flex;
      align-items: center;
      color: #333;
      margin-bottom: 16px;
      .el-button{
        margin-left: 16px;
        padding: 0;
      }
      span{
        display: inline-block;
        color: #777;
        width: 96px;
      }
    }
  }
  /deep/.el-dialog__body{
    padding: 12px 20px;
  }
  </style>

